import { Box, CircularProgress, IconButton, InputAdornment, Paper, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import mypharmaLogo from '../../../assets/images/logo-mypharma.png'
import styles from './styles'

type ChangeProps = {
  loading?: boolean
  classes: any
  handleSubmit: (values: any) => void
  location: any
}

interface State {
  showPassword: boolean
  showPasswordConfirm: boolean
}

class ChangePasswordForm extends Component<ChangeProps> {
  static defaultProps = {
    loading: false,
    handleSubmit: (values: any) => null,
  }

  state: State = {
    showPassword: false,
    showPasswordConfirm: false,
  }

  handleClickShowPassword = () => {
    this.setState((prevState: State) => ({
      showPassword: !prevState.showPassword,
    }))
  }

  handleClickShowPasswordConfirm = () => {
    this.setState((prevState: State) => ({
      showPasswordConfirm: !prevState.showPasswordConfirm,
    }))
  }

  handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  initialValues = {
    password: '',
    passwordConfirm: '',
  }

  render() {
    const { classes, loading, handleSubmit } = this.props
    const { showPassword, showPasswordConfirm } = this.state

    return (
      <Formik
        initialValues={this.initialValues}
        onSubmit={(values, { setSubmitting }) => {
          this.setState({ showPassword: false, showPasswordConfirm: false })
          handleSubmit(values)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Paper className={classNames(classes.paperWrap, classes.petal)}>
              <div className={classes.topBar}>
                <Link to="/" className={classes.brand}>
                  <img src={mypharmaLogo} alt="MyPharma" />
                </Link>
              </div>
              <section className={classes.formWrap}>
                {loading ? (
                  <Box mt={9}>
                    <CircularProgress color="secondary" size="50%" />
                  </Box>
                ) : (
                  <>
                    <h1 className={classes.description}>Antes de continuar</h1>
                    <p>Troque sua senha</p>

                    <Box mb={3} mt={5}>
                      <Field
                        as={TextField}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        label="Nova Senha"
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        required
                        className={classes.input}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" style={{ color: '#fff' }}>
                              <IconButton
                                color="inherit"
                                aria-label="Exibir senha"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Field
                      as={TextField}
                      name="passwordConfirm"
                      type={showPasswordConfirm ? 'text' : 'password'}
                      label="Repita a nova senha"
                      variant="outlined"
                      fullWidth
                      required
                      className={classes.input}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ color: '#fff' }}>
                            <IconButton
                              color="inherit"
                              aria-label="Exibir senha"
                              onClick={this.handleClickShowPasswordConfirm}
                              onMouseDown={this.handleMouseDownPassword}
                            >
                              {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className={classes.btnArea}>
                      <Button variant="contained" color="primary" size="large" type="submit" disabled={isSubmitting || loading}>
                        {isSubmitting || loading ? <CircularProgress color="secondary" size={20} /> : 'Atualizar senha'}
                      </Button>
                    </div>
                    <Box mt={2}>
                      <Button size="large" fullWidth component={Link} to="/">
                        Voltar
                      </Button>
                    </Box>
                  </>
                )}
              </section>
            </Paper>
          </Form>
        )}
      </Formik>
    )
  }
}

export default withStyles(styles)(ChangePasswordForm)
