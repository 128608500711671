import { Box, Button, Typography, withStyles } from '@material-ui/core'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { CustomerProps, InvoiceProps } from '../../context/FinancialContext'
import Store from '../../interfaces/store'
import PaperBlock from '../PaperBlock'
import styles from './style'

export enum PaymentMethodFaturAgil {
  BOLETO_PJBANK = 'BOLETO_PJBANK',
  BOLETO_PJBANK_C2 = 'BOLETO_PJBANK_C2',
  BOLETO_PJBANK_C3 = 'BOLETO_PJBANK_C3',
  TRANSFERENCIA_BANCARIA = 'TRANSFERENCIA_BANCARIA',
  CARTAO_CREDITO_PJBANK = 'CARTAO_CREDITO_PJBANK',
  ITAU = 'ITAU',
}

export enum NotificationType {
  LOCKED = 'LOCKED',
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  WARNING = 'WARNING',
  ATTENTION = 'ATTENTION',
}

interface Props {
  classes: Record<keyof ReturnType<typeof styles>, string>
  store?: Store
  loadCustomer: (cnpj: string) => Promise<void>
  loadInvoices: (cnpj: string) => Promise<void>
  loadNfes: (cnpj: string) => Promise<void>
  customer?: CustomerProps[]
  invoices?: InvoiceProps[]
  openSnackbar: (message: string, severity?: 'error' | 'info' | 'success' | 'warning' | undefined) => void
}

class Financial extends Component<Props> {
  async componentDidUpdate(prevProps: Props) {
    const { loadCustomer, store, loadInvoices, loadNfes } = this.props

    if (store?._id !== undefined && prevProps.store?._id !== store?._id) {
      const cnpj = store?.settings?.config_cnpj?.replace(/[.\-/]/g, '').trim()

      if (cnpj) {
        loadCustomer(cnpj)
        loadInvoices(cnpj)
        loadNfes(cnpj)
      }
    }
  }

  render() {
    const { classes } = this.props

    return (
      <>
        <Box className={classes.boxContent}>
          <Typography className={classes.headertxt}>Financeiro</Typography>

          <Button variant="contained" color="primary" type="button" className={classes.saveButton}>
            <Link to="/" style={{ color: '#FFFFFF', textDecoration: 'none' }}>
              Painel de controle
            </Link>
          </Button>
        </Box>

        <PaperBlock title="Atenção">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography className={classes.text}>
              Devido à migração do nosso ERP financeiro, as informações de histórico, faturas e próximas cobranças não estão mais
              disponíveis nesta área. No entanto, as formas de cobrança e pagamento permanecem inalteradas. O histórico financeiro
              anterior à migração está armazenado de forma segura e pode ser acessado mediante solicitação. <br /> <br /> Para
              consultar cobranças, solicitar arquivos de pagamento ou esclarecer dúvidas, entre em contato diretamente com nosso
              departamento financeiro.
            </Typography>

            <Button variant="contained" color="primary" type="button" className={classes.whatsAppButton}>
              <Link
                to={{ pathname: 'https://api.whatsapp.com/send?phone=5511974125228&text=Falar%20com%20o%20financeiro' }}
                target="_blank"
                style={{ color: '#FFFFFF', textDecoration: 'none' }}
              >
                Clique aqui para falar conosco
              </Link>
            </Button>
          </Box>
        </PaperBlock>
      </>
    )
  }
}

export default withStyles(styles)(Financial)
