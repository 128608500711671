import { createStyles, Theme } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    dialogContentRoot: {
      overflowY: 'hidden',
    },
  })

export default styles
