import { BucketS3 } from "../config"

interface Image {
    name: string,
    folder: string,
    url?: string,
    key?: string,
    [key: string]: any,
}

const createImageUrl = (image: Image) => {
    const bucketS3 = "https://s3-us-west-2.amazonaws.com/myp-public/"
    const pattern = /^https:\/\/s3-us-west-2\.amazonaws\.com\/myp-public\//
    const pattern2 = /^https:\/\/myp-public\.s3\.us-west-2\.amazonaws\.com\//
    const pattern3 = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/

    let hasImageUrl = image.url && image.url.length > 0 ? image.url : null
    const hasImageKey = image.key && image.key.length > 0? image.key : null

    const testUrl = hasImageUrl ? pattern.test(hasImageUrl) || pattern2.test(hasImageUrl) || pattern3.test(hasImageUrl) : false
    if (!testUrl) hasImageUrl = hasImageUrl ? bucketS3.concat(hasImageUrl) : null

    const urlImage = hasImageUrl ? new URL(hasImageUrl).href : hasImageKey
      ? new URL(hasImageKey, BucketS3).href : null

    return urlImage
}

export default createImageUrl