import { Button, CircularProgress, FormControl, Paper, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import mypharmaLogo from '../../../assets/images/logo-mypharma.png'
import styles from './styles'

type RecoverProps = {
  fetching?: boolean
  classes: any
  handleSubmit: (values: any) => void
}

class Recover extends Component<RecoverProps> {
  initialValues = {
    email: '',
  }

  static defaultProps = {
    handleSubmit: (values: any) => null,
  }

  render() {
    const { classes, fetching, handleSubmit } = this.props

    return (
      <Formik
        initialValues={this.initialValues}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Paper className={classNames(classes.paperWrap)}>
              <div className={classes.topBar}>
                <Link to="/" className={classes.brand}>
                  <img src={mypharmaLogo} alt="MyPharma" />
                </Link>
              </div>

              <section className={classes.formWrap}>
                <FormControl className={classes.formControl}>
                  <Field
                    as={TextField}
                    name="email"
                    label="Email Cadastrado"
                    className={classes.field}
                    inputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      className: classes.label,
                    }}
                    required
                  />
                </FormControl>
                <div className={classes.btnArea}>
                  <Button
                    variant="contained"
                    className={classes.btn}
                    size="large"
                    type="submit"
                    disabled={isSubmitting || fetching}
                  >
                    {isSubmitting || fetching ? <CircularProgress color="secondary" size={20} /> : 'REDEFINIR SENHA'}
                  </Button>
                </div>
                <div className={classes.btnArea}>
                  <Button component={Link} to="/" color="inherit" className={classes.ButtonLink}>
                    Voltar ao login
                  </Button>
                </div>
              </section>
            </Paper>
          </Form>
        )}
      </Formik>
    )
  }
}

export default withStyles(styles)(Recover)
