export default {
  skyBlueTheme: {
    palette: {
      primary: {
        light: '#1999F9',
        main: '#2196F3',
        dark: '#0A3463',
        contrastText: '#fff',
      },
      secondary: {
        light: '#2480FF',
        lighter: '#E6EFF7',
        main: '#2480FF',
        dark: '#4C91C4',
        darker: '#0A3463',
        contrastText: '#fff',
      },
      white: {
        light: '#F4FAFF',
        dark: '#FFFFFF',
      },
      orange: '#E58D57',
      black: {
        primary: {
          light: '#000000',
          dark: '#080820'
        }
      },
      red: {
        light: '#E72222',
        dark: '#FF5353'
      },
      purple: {
        primary: {
          light: '#4E00C9',
          dark: '#894BEC'
        }
      },
      yellow: {
        primary: {
          light: '#F5DE58',
          dark : 'FFEC80'
        },
      },
      grey : {
        primary : {
          dark : '#B8C5D0',
          light : '#93A0AC'
        }
      },
      select : {
        light : '#72C2FF',
        dark : '#B0E7FF'
      },
      green : {
        dark : '#58CFB2',
        light : '#00BF91'
      },
    },
  }
} as any
